import React, { useEffect } from "react";
import { Flex, Grid, Text } from "../elements";
import CheckWarning from "../../images/check-warning.png";
import CheckGood from "../../images/check-good.png";

const ZoneRow = ({ zone }) => {
  const {
    id,
    code,
    name,
    channel,
    song,
    message,
    last_played_at,
    last_played_within_required_time,
  } = zone;

  return (
    <Grid h={25} p={10} gridTemplateColumns="1fr 4fr 3fr 4fr 4fr 2fr">
      <span></span>
      <Flex flexDirection="row">
        <Text
          fontFamily="Montserrat"
          fontSize="13px"
          fontWeight="600"
          width="63px"
        >
          Zone {code}:
        </Text>
        <Text
          fontSize="13px"
          pl={10}
          fontFamily="Montserrat"
          className="text-truncate"
        >
          {name}
        </Text>
      </Flex>
      <Text fontFamily="Montserrat" fontSize="13px">
        {channel}
      </Text>
      <Text fontFamily="Montserrat" fontSize="13px">
        {song}
      </Text>
      <Text fontFamily="Montserrat" fontSize="13px">
        {message}
      </Text>
      <div>
        <div className="last-played">
          <span
            style={{
              fontFamily: "Montserrat",
              fontSize: "13px",
              display: "flex",
              flexDirection: "row",
              width: `${last_played_at === "Song not set" ? "107px" : "115px"}`,
            }}
          >
            {last_played_at}{" "}
            {last_played_within_required_time ? (
              <img
                src={CheckGood}
                width="14px"
                style={{ alignSelf: "center" }}
              />
            ) : (
              <img
                style={{ alignSelf: "center" }}
                src={CheckWarning}
                width="14px"
              />
            )}
          </span>
        </div>
      </div>
    </Grid>
  );
};

export default ZoneRow;
